import { Environment } from 'goodmaps-sdk';

const config = {
  NAME: 'Internal',
  TRACKING_ID: '',
  API_URL: 'https://dev.api.goodmaps.io/servicesv2-services',
  BUILDING_URL: 'https://dev.api.goodmaps.io/servicesv2-buildingmanagement/building',
  LINT_BUILDING_URL: 'https://dev.api.goodmaps.io/servicesv2-lint',
  IMDF_BUILDING_URL: 'https://dev.api.goodmaps.io/servicesv2-imdf',
  ENV_ID: Environment.Dev,
  userPoolId: 'us-east-2_SlfRyZYLJ',
  userPoolWebClientId: 'ajbp2i5di9g3bjg597u4bqeim',
  region: 'us-east-2',
};

export default config;
